import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <iframe
    src={videoSrcURL}
    title={videoTitle}
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    frameBorder="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowFullScreen
    height="300"
    width="300"
  />
)
export default Video
